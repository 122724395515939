import { useCallback, useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Divider,
  Link,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useAuth } from "../../../hooks/use-auth";
import { useMounted } from "../../../hooks/use-mounted";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { gtm } from "../../../lib/gtm";
import { surveyApi } from "../../../api/survey-api";
import { SurveyBasicDetails } from "./surveyBasicDetails";
import { SurveyQuestions } from "./surveyQuestions";

const tabs = [
  { label: "Détails", value: "details" },
  { label: "Questions", value: "Questions" },
];

const SurveyDetails = () => {
  const { surveyId } = useParams();

  const isMounted = useMounted();

  const [survey, setsurvey] = useState([]);
  const [currentTab, setCurrentTab] = useState("details");
  const { user } = useAuth();

  const getsurveyData = useCallback(async () => {
    try {
      const data = await surveyApi.getSurveyById(user.admin_id, surveyId);
      if (isMounted()) {
        setsurvey(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    if (surveyId) {
      getsurveyData();
      gtm.push({ event: "page_view" });
    }
  }, [surveyId]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        pt: 10,
        mx: 3,
      }}
    >
      <Box sx={{ mb: 1 }}>
        <RouterLink to="/:tenant/dashboardsurvey" style={{ textDecoration: "none" }}>
          <Link
            color="textPrimary"
            component="a"
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="subtitle2">
              Questionnaires de satisfaction
            </Typography>
          </Link>
        </RouterLink>
      </Box>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid
          item
          sx={{
            alignItems: "center",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div>
            <Typography variant="h4">{survey.name}</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2
              }}
            >
              <Typography variant="subtitle2">Id du questionnaire :</Typography>
              <Chip label={surveyId} size="small" sx={{ ml: 1 }} />
            </Box>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SurveyBasicDetails survey={survey} setsurvey={setsurvey} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <SurveyQuestions survey={survey} setsurvey={setsurvey} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SurveyDetails;
