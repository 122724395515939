import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Button, FormHelperText, InputLabel, TextField, Typography } from '@mui/material';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import toast from 'react-hot-toast';
import { makeStyles } from '@material-ui/styles';

// Objet de style servant à customiser les champs d'identifiants
const useTextFieldStyles = makeStyles({
    root: {
        '& label': {
          color: '#251E74', // Couleur lorsque le label est en focus
          paddingLeft: '1rem',
          fontFamily: '"Montserrat", sans-serif'
        },
        '& label.Mui-focused': {
          color: '#251E74', // Couleur lorsque le label est en focus
        },
        '& .MuiInput-underline:before': {
          borderColor: '#251E74', // Couleur de la bordure après avoir rempli
        },
        '& .MuiInput-underline:after': {
          borderColor: '#251E74', // Couleur de la bordure après avoir rempli
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderColor: '#251E74', // Couleur du bord inférieur au survol
        },
        '& .MuiFormHelperText-root.Mui-error': {
          color: '#6b0c01', // Changer la couleur de l'attribut error en utilisant la couleur d'erreur du thème
          fontWeight: 'bold'
        },
        '& .MuiInputLabel-root.Mui-error': {
          color: '#251E74', // Changer la couleur du label en cas d'erreur en utilisant la couleur d'erreur du thème
        },
        '& input:-webkit-autofill': {
          'transition': 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
          'background-color': 'initial !important', // Conservez la couleur de fond initiale
          'color': 'initial !important' 
        },
        "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#251E74", // Default border color
        },
        "&:hover fieldset": {
          borderColor: "#5d5db7", // Border color on hover
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3B2755", // Border color when focused
        },
        "&.Mui-error fieldset": {
          borderColor: "#FF0000", // Border color when there's an error
        }
      }
    },
  });

// Formulaire de connexion avec la bibliothèque Formik (décommenter plus tard le bouton "Mot de passe oublié?")
export const JWTLogin = (props) => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth();
  const classes = useTextFieldStyles();
  const { handleForgottenPassword, setLoginError } = props;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("L'adresse mail doit être valide")
        .max(255)
        .required("Adresse mail requise"),
      password: Yup.string().max(255).required("Mot de passe requis"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const res = await login(values.email, values.password);

        if (isMounted() && res) {
          setLoginError(null);  // Clear login error
          navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard`, { replace: true });
        } else {
          helpers.setErrors({ submit: "Email ou mot de passe incorrect(s)" });
        }
      } catch (err) {
        console.error(err);

        if (isMounted()) {
          const errorMessage =
            err instanceof TypeError && err.message === "Failed to fetch"
              ? "Connexion impossible : problème au niveau du serveur"
              : err.message;

          helpers.setErrors({ submit: errorMessage });
        }
      } finally {
        if (isMounted()) {
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit} {...props}>
      <InputLabel sx={{ mt: 3, mb: 1, color: 'black', fontWeight: 'medium', fontFamily: 'Montserrat' }}>
        Adresse e-mail
      </InputLabel>
      <TextField
        variant="outlined"
        error={Boolean(formik.touched.email && formik.errors.email)}
        size="small"
        fullWidth
        helperText={formik.touched.email && formik.errors.email}
        name="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        className={classes.root}
        sx={{
          '& .MuiOutlinedInput-root fieldset': {
            borderColor: formik.errors.email ? '#FF0000' : '#251E74',
          },
        }}
      />

      <InputLabel sx={{ mt: 3, mb: 1, color: 'black', fontWeight: 'medium', fontFamily: 'Montserrat' }}>
        Mot de passe
      </InputLabel>
      <TextField
        variant="outlined"
        error={Boolean(formik.touched.password && formik.errors.password)}
        size="small"
        fullWidth
        helperText={formik.touched.password && formik.errors.password}
        name="password"
        onChange={formik.handleChange}
        type="password"
        value={formik.values.password}
        className={classes.root}
        sx={{
          '& .MuiOutlinedInput-root fieldset': {
            borderColor: formik.errors.password ? '#FF0000' : '#251E74',
          },
        }}
      />

      {formik.errors.submit && (
        <FormHelperText error sx={{ mt: 2 }}>
          {formik.errors.submit}
        </FormHelperText>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Typography
          sx={{ cursor: 'pointer', color: '#A3A3A3', fontFamily: 'Montserrat', fontSize: 14 }}
          onClick={handleForgottenPassword}
        >
          Mot de passe oublié ?
        </Typography>
      </Box>

      <Button
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        sx={{
          mt: 3,
          backgroundColor: '#251E74',
          color: 'white',
          '&:hover': {
            backgroundColor: 'white',
            color: '#251E74',
          },
        }}
      >
        Se connecter
      </Button>
    </form>
  );
};
