import { useEffect, useState } from 'react';
import { Box, Card, Typography, Grid, TextField, Button, InputLabel } from '@mui/material';
import { GuestGuard } from '../../components/authentication/guest-guard';
import { useAuth } from '../../hooks/use-auth';
import { gtm } from '../../lib/gtm';
import pauline from './../../images/new_pauline_1.png';
import Logo_Kalanke from './../../images/Logo-Kalanke-Blanc.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { authApi } from "../../api/auth-api";
import { makeStyles } from '@material-ui/styles';
import { useNavigate } from 'react-router-dom';

const Tenant_page = () => {
  const useTextFieldStyles = makeStyles({
    root: {
      '& label': {
        color: '#251E74',
        paddingLeft: '1rem',
        fontFamily: '"Montserrat", sans-serif'
      },
      '& label.Mui-focused': { color: '#251E74' },
      '& .MuiInput-underline:before': { borderColor: '#251E74' },
      '& .MuiInput-underline:after': { borderColor: '#251E74' },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderColor: '#251E74' },
      '& .MuiFormHelperText-root.Mui-error': {
        color: '#6b0c01',
        fontWeight: 'bold'
      },
      '& .MuiInputLabel-root.Mui-error': { color: '#251E74' },
      '& input:-webkit-autofill': {
        'transition': 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
        'background-color': 'initial !important',
        'color': 'initial !important'
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": { borderColor: "#251E74" },
        "&:hover fieldset": { borderColor: "#5d5db7" },
        "&.Mui-focused fieldset": { borderColor: "#3B2755" },
        "&.Mui-error fieldset": { borderColor: "#FF0000" }
      }
    },
  });

  const [tenantError, setTenantError] = useState(null); // For server-side errors
  const navigate = useNavigate();
  const classes = useTextFieldStyles();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const formik = useFormik({
    initialValues: {
      tenant: '',
      submit: null,
    },
    validationSchema: Yup.object({
      tenant: Yup.string().required("Nom d'organisme requis"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const res = await authApi.getOrgName(values.tenant);
        console.log(res);

        if (res.name) {
          setTenantError(null);  // Clear any server-side error
          helpers.setErrors({}); // Clear form errors
          helpers.resetForm();   // Reset form on success
          navigate(`/${values.tenant}`, { replace: true });
        } else {
          helpers.setErrors({ tenant: "Nom d'organisme incorrect" });
        }
      } catch (err) {
        console.error(err);

        if (err instanceof TypeError && err.message === 'Failed to fetch') {
          setTenantError('Connexion impossible : problème au niveau du serveur');
        } else {
          setTenantError('Une erreur inattendue est survenue.');
        }

        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <Grid container style={{ height: '100vh', width: '100vw' }}>
      {/* Left Side (Logo and Background) */}
      <Grid item style={{ height: '100%', width: '47%', backgroundColor: '#5d5db7', position: 'relative' }}>
        <img
          alt="logo"
          src={Logo_Kalanke}
          style={{ width: '200px', position: 'absolute', bottom: '60px', left: '60px', zIndex: 2 }}
        />
        <div style={{ width: '100%', height: '100%', display: 'flex', zIndex: 0 }}>
          <img alt="Image" src={pauline} style={{ width: '100%', opacity: 0.5 }} />
        </div>
      </Grid>

      {/* Right Side (Form) */}
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ height: '100%', width: '53%', backgroundColor: '#ffffff' }}
      >
        <div>
          <Typography style={{ textAlign: 'center', color: '#5d5db7', fontSize: '24px', fontFamily: '"Montserrat", sans-serif' }}>
            Kalanke
          </Typography>
          <Typography
            variant='h3'
            style={{ textAlign: 'center', color: '#5d5db7', fontSize: '26px', fontWeight: 900, fontFamily: '"Montserrat", sans-serif' }}
          >
            Back-Office
          </Typography>
        </div>

        <Card elevation={10} style={{ width: '50%', padding: '40px', borderRadius: 25, backgroundColor: 'white' }}>
          <form noValidate onSubmit={formik.handleSubmit}>
            <InputLabel
              sx={{ mt: 3, px: 0, mb: '5px', color: 'black', fontWeight: 'medium', fontSize: 12, fontFamily: '"Montserrat", sans-serif' }}
            >
              Votre organisme
            </InputLabel>

            <TextField
  variant="outlined"
  error={Boolean(formik.touched.tenant && formik.errors.tenant)}
  size="small"
  fullWidth
  helperText={formik.touched.tenant && formik.errors.tenant}
  name="tenant"
  onChange={formik.handleChange}
  value={formik.values.tenant}
  className={classes.root}
  InputProps={{
    style: {
      // This will change the input text color dynamically
      color: Boolean(formik.touched.tenant && formik.errors.tenant) ? 'red' : '#251E74',
    },
  }}
  sx={{
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input': {
      color: 'red', // Text color in error state
    },
    '& .MuiOutlinedInput-input': {
      color: '#251E74', // Default input text color
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red', // Red border in error state
    },
     p: 0 
  }}
  
/>
            {tenantError && (
              <Typography color="error" sx={{ mt: 1 }}>
                {tenantError}
              </Typography>
            )}

            <Box sx={{ mt: 1, p: 0 }}>
              <Button
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                sx={{
                  mt: 3,
                  fontFamily: '"Montserrat", sans-serif',
                  color: 'white',
                  padding: '5px',
                  background: '#251E74',
                  border: '1px solid #251E74',
                  '&:hover': {
                    color: '#251E74',
                    backgroundColor: 'white'
                  },
                  fontSize: 18,
                }}
              >
                Valider
              </Button>
            </Box>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};

Tenant_page.getLayout = (page) => <GuestGuard>{page}</GuestGuard>;

export default Tenant_page;
