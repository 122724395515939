import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  useMediaQuery,
  Typography,
  TextField,
  Select,
  MenuItem,
  Container,
  Switch,
  Tooltip,
  InputAdornment,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  FormControlLabel,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { paramsApi } from "../../../api/params-api";
import { useMounted } from "../../../hooks/use-mounted";
import { stepApi } from "../../../api/step-api";
import { auditsApi } from "../../../api/audit-api";
import { validate } from "json-schema";
import { set } from "lodash";

export const Parameters = () => {
  const { user } = useAuth();
  const isMounted = useMounted();
  const [automaticMail, setAutomaticMail] = useState(true);
  const [automaticMailToCustomer, setAutomaticMailToCustomer] = useState(true);


  const getParams = useCallback(async () => {
    try {
      const automaticMail = await paramsApi.getParamAutomaticMail(
        user.admin_id
      );
      const automaticMailToCustomer =
        await paramsApi.getParamAutomaticMailToCustomer(user.admin_id);
      if (isMounted()) {
        setAutomaticMail(automaticMail.automatic_mails_send === 1);
        setAutomaticMailToCustomer(
          automaticMailToCustomer.automatic_mails_send_to_customer === 1
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getSteps = useCallback(async () => {
    try {
      const steps = await stepApi.getAllSteps(user.admin_id);
      if (isMounted()) {
        setSteps(steps);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getAudits = useCallback(async () => {
    try {
      const audits = await auditsApi.getAllAudits(user.admin_id);
      if (isMounted()) {
        setAudits(audits);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getParams();
    getSteps();
    getAudits();
  }, [getParams, getSteps, getAudits]);

  const setParamAutomaticMail = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMail(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleChangeAutomaticMail = (value) => {
    setAutomaticMail(!value);
    let paramAutomaticMailBool = true;
    if (!value) {
      paramAutomaticMailBool = 1;
    } else {
      paramAutomaticMailBool = 0;
    }
    setParamAutomaticMail(paramAutomaticMailBool);
  };

  const setParamAutomaticMailToCustomer = useCallback(async (value) => {
    try {
      await paramsApi.setParamAutomaticMailToCustomer(user.admin_id, value);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleChangeAutomaticMailToCustomer = (value) => {
    setAutomaticMailToCustomer(!value);
    let paramAutomaticMailBool = true;
    if (!value) {
      paramAutomaticMailBool = 1;
    } else {
      paramAutomaticMailBool = 0;
    }
    setParamAutomaticMailToCustomer(paramAutomaticMailBool);
  };

  return (
    <>
      {user.access_level === 0 ? (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 11,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 5 }}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
                    Paramètres
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 3 }}>
              <MenuItem>
                <Switch
                  checked={automaticMail}
                  onChange={() => {
                    handleChangeAutomaticMail(automaticMail);
                  }}
                />
                <Tooltip title="Contrôle les tâches cron">
                  <Typography>Envoi de mails automatique</Typography>
                </Tooltip>
              </MenuItem>
              <MenuItem>
                <Switch
                  checked={automaticMailToCustomer}
                  onChange={() => {
                    handleChangeAutomaticMailToCustomer(
                      automaticMailToCustomer
                    );
                  }}
                />
                <Tooltip title="Contrôle l'envoi de mail à l'apprenant">
                  <Typography>
                    Envoi de mails automatique à l'apprenant
                  </Typography>
                </Tooltip>
              </MenuItem>
            </Box>
          </Container>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 11,
          }}
        >
          <Container maxWidth="xl">
            <Box sx={{ mb: 5 }}>
              <Grid container justifyContent="space-between" spacing={3}>
                <Grid item>
                  <Typography>
                    Vous n'avez pas les droits pour voir cette page
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Parameters;
